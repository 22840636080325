<template>
  <div id="agencies-form-create">
    <b-form>
      <!-- -->
      <div class="divInfo" :style="sheet">
        <p class="flex">
          <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
          Campos obligatorios (*), Resolución portada: 1366x768
        </p>
      </div>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Logo*" label-for="logo">
            <b-form-file
              id="logo"
              type="file"
              browse-text="Formatos: svg - jpeg - jpg - png"
              accept=".svg, .jpeg, .jpg, .png"
              @change="_previewLogo"
              placeholder="Selecciona una imagen"
            />
          </b-form-group>
          <b-row class="mt-2" v-if="editForm.media_logo != undefined">
            <b-col v-if="editForm.media_logo.full_url === ''" cols="12">
              <div class="preview">
                <h5>Previsualización Miniatura</h5>
                <!-- <h5><strong>1366x768</strong></h5> -->
              </div>
            </b-col>
            <b-col v-else cols="12">
              <div class="container" width="300">
                <img class="image" :src="editForm.media_logo.full_url" />
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Portada*" label-for="portada">
            <b-form-file
              id="cover_page"
              type="file"
              browse-text="Formatos: jpeg - jpg - png"
              accept=".jpeg, .jpg, .png, .svg"
              placeholder="Selecciona una imagen"
              @change="_previewPortada"
            />
          </b-form-group>
          <b-row class="mt-2" v-if="editForm.media_coverpage != undefined">
            <b-col v-if="editForm.media_coverpage.full_url === ''" cols="12">
              <div class="preview">
                <h5>Previsualización Miniatura</h5>
                <!-- <h5><strong>1366x768</strong></h5> -->
              </div>
            </b-col>
            <b-col v-else cols="12">
              <div class="container" width="300">
                <img class="image" :src="editForm.media_coverpage.full_url" />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <br />

      <!-- -->
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Nombre de fantasía*" label-for="name">
            <b-form-input
              id="name"
              v-model="editForm.name"
              name="name"
              :state="nameErrors.length > 0 ? false : null"
              @input="$v.editForm.name.$touch()"
            />
            <small class="text-danger">{{ nameErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Dirección*" label-for="address">
            <b-form-input
              id="address"
              v-model="editForm.address"
              name="address"
              :state="addressErrors.length > 0 ? false : null"
              @input="$v.editForm.address.$touch()"
            />
            <small class="text-danger">{{ addressErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Razón social*" label-for="official_name">
            <b-form-input
              id="official_name"
              v-model="editForm.official"
              name="official_name"
              :state="officialErrors.length > 0 ? false : null"
              @input="$v.editForm.official.$touch()"
            />
            <small class="text-danger">{{ officialErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Teléfono" label-for="phone">
            <b-form-input
              id="phone"
              v-model="editForm.phone"
              name="phone"
              :state="phoneErrors.length > 0 ? false : null"
              @input="$v.editForm.phone.$touch()"
            />
            <small class="text-danger">{{ phoneErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Contacto*" label-for="contact">
            <b-form-input
              id="contact"
              v-model="editForm.contact"
              name="contact"
              :state="contactErrors.length > 0 ? false : null"
              @input="$v.editForm.contact.$touch()"
            />
            <small class="text-danger">{{ contactErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Email" label-for="email">
            <b-form-input
              id="email"
              v-model="editForm.email"
              name="email"
              :state="emailErrors.length > 0 ? false : null"
              @input="$v.editForm.email.$touch()"
            />
            <small class="text-danger">{{ emailErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="URL Sitio" label-for="url">
            <b-form-input
              id="url"
              v-model="editForm.url_site"
              name="url"
              @input="$v.editForm.contact.$touch()"
            />
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Orden" label-for="orden">
            <b-form-input
              id="orden"
              v-model="editForm.orden"
              name="url"
              :state="ordenErrors.length > 0 ? false : null"
              @input="$v.editForm.orden.$touch()"
            />
            <small class="text-danger">{{ ordenErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group
            label="Descripción de la inmobiliaria"
            label-for="description"
          >
            <b-form-textarea
              v-model="editForm.description"
              id="description"
              rows="3"
            />
          </b-form-group>
        </b-col>

        <b-col xl="6" sm="12">
          <b-form-group
            label="Cantidad de planes"
            label-for="Cantidad de planes"
          >
            <b-form-input
              id="Restricciones"
              v-model="editForm.restriction_quantity"
              name="restriction_quantity"
              :state="restrictionQuantityErrors.length > 0 ? false : null"
              @input="$v.editForm.restriction_quantity.$touch()"
            />
            <small class="text-danger">{{
              restrictionQuantityErrors[0]
            }}</small>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
          <b-form-group>
            <div>
              <b-overlay
                :show="overlayForm"
                opacity="0.5"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="_onHidden()"
              >
                <b-button
                  block
                  ref="button"
                  :disabled="overlayForm"
                  variant="primary"
                  @click.prevent="_updateAgency()"
                >
                  <feather-icon icon="SaveIcon" class="mr-50" />
                  <span class="align-middle"> Guardar </span>
                </b-button>
              </b-overlay>
            </div>
          </b-form-group>
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, numeric, email, maxLength } from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'AgenciesFormCreate',
  props: ['agency'],
  data () {
    return {
      overlayForm: false,
      sheet: {
        backgroundColor: '#EFEFEF'
      },
      editForm: {
        id: '',
        name: '',
        address: '',
        official: '',
        phone: '',
        contact: '',
        email: '',
        url_site: '',
        description: '',
        orden: '',
        media_logo: { full_url: '' },
        media_coverpage: { full_url: '' },
        logo: [],
        cover_page: [],
        restriction_quantity: 0
      }
    }
  },
  validations: {
    editForm: {
      name: {
        required
      },
      address: {
        required
      },
      official: {
        required
      },
      orden: {
        required,
        numeric
      },
      phone: {
        numeric,
        maxLength: maxLength(9)
      },
      contact: {
        required
      },
      email: {
        email
      },
      url_site: {},
      logo: {},
      cover_page: {},
      restriction_quantity: {
        required,
        numeric
      }
    }
  },
  beforeMount () {
    this.editForm.restriction_quantity = this.agency.plansrestrictions
      ? this.agency.plansrestrictions.quantity
        ? this.agency.plansrestrictions.quantity
        : 0
      : 0
    var today = new Date()
    var time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()

    if (Array.isArray(this.agency.medias)) {
      this.agency.medias.forEach(element => {
        if (element) {
          if (element.mediatype.id == 2) {
            element.full_url = element.full_url + '?' + time
            this.editForm.media_coverpage = element
          }
          if (element.mediatype.id == 1) {
            element.full_url = element.full_url + '?' + time
            this.editForm.media_logo = element
          }
        }
      })
    }

    this._showAgencies()
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.editForm.name.$dirty) return errors
      !this.$v.editForm.name.required && errors.push('El nombre es obligatorio')
      return errors
    },
    addressErrors () {
      const errors = []
      if (!this.$v.editForm.address.$dirty) return errors
      !this.$v.editForm.address.required &&
        errors.push('La dirección es obligatoria')
      return errors
    },
    officialErrors () {
      const errors = []
      if (!this.$v.editForm.official.$dirty) return errors
      !this.$v.editForm.official.required &&
        errors.push('La razón social es obligatoria')
      return errors
    },
    ordenErrors () {
      const errors = []
      if (!this.$v.editForm.orden.$dirty) return errors
      !this.$v.editForm.orden.required && errors.push('El orden es obligatorio')
      !this.$v.editForm.orden.numeric &&
        errors.push('El orden debe ser numerico')
      return errors
    },
    restrictionQuantityErrors () {
      const errors = []
      if (!this.$v.editForm.restriction_quantity.$dirty) return errors
      !this.$v.editForm.restriction_quantity.required &&
        errors.push('La cantidad de restriccion es obligatoria')
      !this.$v.editForm.restriction_quantity.numeric &&
        errors.push('La cantidad de restriccion debe ser numerico')
      return errors
    },
    phoneErrors () {
      const errors = []
      if (!this.$v.editForm.phone.$dirty) return errors
      !this.$v.editForm.phone.numeric &&
        errors.push('El teléfono debe ser numerico')
      !this.$v.editForm.phone.maxLength &&
        errors.push('El teléfono debe contener máximo 9 dígitos')
      return errors
    },
    contactErrors () {
      const errors = []
      if (!this.$v.editForm.contact.$dirty) return errors
      !this.$v.editForm.contact.required &&
        errors.push('El nombre de contacto es obligatoria')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.editForm.email.$dirty) return errors
      !this.$v.editForm.email.email && errors.push('El email debe ser valido')
      return errors
    },
    logoErrors () {
      const errors = []
      if (!this.$v.editForm.logo.$dirty) return errors
      !this.$v.editForm.logo.required &&
        errors.push('La portada es obligatoria')
      return errors
    },
    coverPageErrors () {
      const errors = []
      if (!this.$v.editForm.cover_page.$dirty) return errors
      !this.$v.editForm.cover_page.required &&
        errors.push('La portada es obligatoria')
      return errors
    }
  },
  methods: {
    ...mapActions('agencies', ['updateAgencies']),
    async _updateAgency () {
      this.$v.editForm.$touch()
      if (!this.$v.editForm.$invalid) {
        this.overlayForm = true
        await this.updateAgencies(this.editForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Inmobiliaria modificada con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success'
              }
            })

            this.$router.push({ name: 'agencies' })
          })
          .catch(err => {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar la inmobiliaria',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          })
          .finally(() => {
            this.overlayForm = false
          })
      }
    },
    _showAgencies () {
      this.editForm.id = this.agency.id
      this.editForm.name = this.agency.name
      this.editForm.official = this.agency.official_name
      this.editForm.address = this.agency.address
      this.editForm.phone = this.agency.phone
      this.editForm.contact = this.agency.representative
      this.editForm.email = this.agency.email
      this.editForm.url_site = this.agency.url_site
      this.editForm.description = this.agency.description
      this.editForm.orden = this.agency.orden
    },
    _onHidden () {
      if (this.$refs.button != null) {
        this.$refs.button.focus()
      }
    },
    _previewLogo (event) {
      if (event.target.files[0] != null) {
        if (
          event.target.files[0].type === 'image/svg+xml' ||
          event.target.files[0].type === 'image/jpeg' ||
          event.target.files[0].type === 'image/jpg' ||
          event.target.files[0].type === 'image/png'
        ) {
          this.editForm.media_logo.full_url = URL.createObjectURL(
            event.target.files[0]
          )
          this.editForm.logo = event.target.files[0]
        } else {
          document.getElementById('logo').value = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Formato de archivo incorrecto',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        }
      } else {
      }
    },
    _previewPortada (event) {
      if (event.target.files[0] != null) {
        if (
          event.target.files[0].type === 'image/jpeg' ||
          event.target.files[0].type === 'image/jpg' ||
          event.target.files[0].type === 'image/png' ||
          event.target.files[0].type === 'image/svg+xml'
        ) {
          this.editForm.media_coverpage.full_url = URL.createObjectURL(
            event.target.files[0]
          )
          this.editForm.cover_page = event.target.files[0]
        } else {
          document.getElementById('cover_page').value = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Formato de archivo incorrecto',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        }
      } else {
      }
    }
  }
}
</script>
