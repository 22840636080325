<template>
  <div id="agencies-edit">
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <b-col cols="12">
          <agencies-form-edit :agency="agency"></agencies-form-edit>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import AgenciesFormEdit from '../agencies/components/AgenciesFormEdit'

  export default{
    name: 'AgenciesEdit',
    components: {AgenciesFormEdit},
    props: ['agency'],
    data() {
      return {
      }
    },
  }
</script>